import TYPES from './assetTypes'
import { ID_Fields, Data_Fields, Auscult_Fields } from './formFields'

const linearAssetIDFields = [
  ID_Fields.codigo,
  ID_Fields.concesionaria,
  ID_Fields.autopista,
  ID_Fields.eje,
  ID_Fields.sentido,
  ID_Fields.lado,
  ID_Fields.pkInicial,
  ID_Fields.pkFinal,
  ID_Fields.longitud,
  ID_Fields.latitud,
  ID_Fields.ccaa,
  ID_Fields.provincia,
  ID_Fields.municipioSelect,
  ID_Fields.municipio,
  ID_Fields.fechaAlta
  //ID_Fields.fechaBaja
]

const pointAssetIDFields = [
  ID_Fields.codigo,
  ID_Fields.concesionaria,
  ID_Fields.autopista,
  ID_Fields.eje,
  ID_Fields.sentido,
  ID_Fields.lado,
  ID_Fields.pk,
  ID_Fields.pkReal,
  ID_Fields.longitud,
  ID_Fields.latitud,
  ID_Fields.ccaa,
  ID_Fields.provincia,
  ID_Fields.municipioSelect,
  ID_Fields.municipio,
  ID_Fields.fechaAlta
  //ID_Fields.fechaBaja
]

const commonITSDataFields = [
  Data_Fields.equipo,
  Data_Fields.numEquipo,
  Data_Fields.emplazamiento,
  Data_Fields.ubicacionTecnica,
  Data_Fields.denominacion
]

const linearStructureAssetIDFields = [
  ID_Fields.codigoi3met,
  ID_Fields.concesionaria,
  ID_Fields.autopista,
  ID_Fields.eje,
  ID_Fields.sentido,
  ID_Fields.nombre,
  ID_Fields.pkInicial,
  ID_Fields.pkFinal,
  ID_Fields.lado,
  ID_Fields.longitud,
  ID_Fields.latitud,
  ID_Fields.ccaa,
  ID_Fields.provincia,
  ID_Fields.municipioSelect,
  ID_Fields.municipio
]

const pointStructureAssetIDFields = [
  ID_Fields.codigoi3met,
  ID_Fields.concesionaria,
  ID_Fields.autopista,
  ID_Fields.eje,
  ID_Fields.sentido,
  ID_Fields.nombre,
  ID_Fields.pk,
  ID_Fields.lado,
  ID_Fields.longitud,
  ID_Fields.latitud,
  ID_Fields.ccaa,
  ID_Fields.provincia,
  ID_Fields.municipioSelect,
  ID_Fields.municipio
]

const commonDataStructuresODT = [
  Data_Fields.cronoConstruccion,
  Data_Fields.tipologiaGeneral,
  Data_Fields.longitudTotal,
  Data_Fields.anchuraTotal,
  Data_Fields.superficieTotal,
  Data_Fields.numVanos
]

const commonDataOtherStructures = [
  Data_Fields.cronoConstruccion,
  Data_Fields.tipologiaGeneral,
  Data_Fields.longitudTotal
]

const commonAuscultData = [
  Auscult_Fields.ultimInspeccionPpal,
  Auscult_Fields.califUltimInspeccionPpal,
  Auscult_Fields.ultimInspeccionBasica
]

const baculoDataFields = [
  Data_Fields.equipo,
  Data_Fields.ubicacionTecnica,
  Data_Fields.tipoEquipo,
  Data_Fields.denominacion,
  Data_Fields.tipoObjeto,
  Data_Fields.emplazamiento,
  Data_Fields.elementoPEP,
  Data_Fields.cuadroMandoReferencia,
  Data_Fields.tipoSoporteAlumbrado,
  Data_Fields.elementoSustentado
]

const AssetTypeFields = {
  // assetType -> { id: [], data: [] }
  [TYPES.VPP]: {
    id: pointStructureAssetIDFields,
    data: commonDataStructuresODT,
    auscultData: commonAuscultData
  },
  [TYPES.ODT]: {
    id: pointStructureAssetIDFields,
    data: commonDataStructuresODT,
    auscultData: commonAuscultData
  },
  [TYPES.POP]: {
    id: pointStructureAssetIDFields,
    data: commonDataStructuresODT,
    auscultData: commonAuscultData
  },
  [TYPES.PORTICOS]: {
    id: pointStructureAssetIDFields,
    data: commonDataStructuresODT,
    auscultData: commonAuscultData
  },
  [TYPES.BANDEROLAS]: {
    id: pointStructureAssetIDFields,
    data: commonDataStructuresODT,
    auscultData: commonAuscultData
  },
  [TYPES.MUROS]: {
    id: pointStructureAssetIDFields,
    data: commonDataStructuresODT,
    auscultData: commonAuscultData
  },
  [TYPES.ARQUETAS_POZOS]: {
    id: pointAssetIDFields,
    data: [
      Data_Fields.longitud,
      Data_Fields.diametro,
      Data_Fields.descripcion,
      Data_Fields.tipologiaElementoArquetas,
      Data_Fields.codigoElemento,
      Data_Fields.dimensionesPlanta
    ]
  },
  [TYPES.CUNETAS]: {
    id: linearAssetIDFields,
    data: [
      Data_Fields.longitud,
      Data_Fields.materialElemento,
      Data_Fields.pendiente,
      Data_Fields.tipologiaElementoCunetasCaz,
      Data_Fields.ubicacion
    ]
  },
  [TYPES.CAZ]: {
    id: linearAssetIDFields,
    data: [
      Data_Fields.longitud,
      Data_Fields.materialElemento,
      Data_Fields.tipologiaElementoCunetasCaz
    ]
  },
  [TYPES.BORDILLOS]: {
    id: linearAssetIDFields,
    data: [
      Data_Fields.longitud,
      Data_Fields.materialElemento,
      Data_Fields.tipologiaElementoTexto, //dropdown con 1 solo "option"?
      Data_Fields.ubicacion
    ]
  },
  [TYPES.BAJANTES]: {
    id: pointAssetIDFields,
    data: [
      Data_Fields.longitud,
      Data_Fields.materialElemento,
      Data_Fields.tipologiaElementoCunetasCaz
    ]
  },
  [TYPES.VALLA_CERRAMIENTO]: {
    id: linearAssetIDFields,
    data: [
      Data_Fields.longitud,
      Data_Fields.altura,
      Data_Fields.materialElemento
    ]
  },
  [TYPES.BARRERA_METALICA]: {
    id: linearAssetIDFields,
    data: [
      Data_Fields.longitud,
      Data_Fields.altura,
      Data_Fields.materialElemento,
      Data_Fields.anoColocacion,
      Data_Fields.elementosProtegidos,
      Data_Fields.fabricanteElemento,
      Data_Fields.nivelContencion,
      Data_Fields.soporteBarrera,
      Data_Fields.tipoBarreraMetalica
    ]
  },
  [TYPES.BARRERA_HORMIGON]: {
    id: linearAssetIDFields,
    data: [
      Data_Fields.longitud,
      Data_Fields.altura,
      Data_Fields.materialElemento,
      Data_Fields.anoColocacion,
      Data_Fields.elementosProtegidos,
      Data_Fields.fabricanteElemento,
      Data_Fields.nivelContencion,
      Data_Fields.soporteBarrera,
      Data_Fields.tipoBarreraHormigon
    ]
  },
  [TYPES.PRETIL]: {
    id: linearAssetIDFields,
    data: [
      Data_Fields.longitud,
      Data_Fields.altura,
      Data_Fields.materialElemento,
      Data_Fields.anoColocacion,
      Data_Fields.elementosProtegidos,
      Data_Fields.fabricanteElemento,
      Data_Fields.nivelContencion,
      Data_Fields.soporteBarrera,
      Data_Fields.tipoPretil
    ]
  },
  [TYPES.PANTALLAS_ANTIRRUIDO]: {
    id: linearAssetIDFields,
    data: [
      Data_Fields.longitud,
      Data_Fields.altura,
      Data_Fields.materialElemento,
      Data_Fields.anoColocacion,
      Data_Fields.fabricanteElemento,
      Data_Fields.tipoPantalla,
      Data_Fields.cimentacion
    ]
  },
  [TYPES.SENALIZACION_HORIZONTAL]: {
    id: linearAssetIDFields,
    data: [
      Data_Fields.longitud,
      Data_Fields.anchuraCm,
      Data_Fields.anoColocacion,
      Data_Fields.discontinuidad,
      Data_Fields.discontinuidadExterior,
      Data_Fields.discontinuidadInterior,
      Data_Fields.discontinuidadEje,
      Data_Fields.tipoPintura,
      Data_Fields.resalte,
      Data_Fields.fechaRepintadoExterior,
      Data_Fields.fechaRepintadoInterior,
      Data_Fields.fechaRepintadoEje
    ]
  },
  [TYPES.SENALIZACION_VERTICAL]: {
    id: pointAssetIDFields,
    data: [
      Data_Fields.materialElemento,
      Data_Fields.descripcion,
      Data_Fields.anoColocacion,
      Data_Fields.fabricanteElemento,
      Data_Fields.superficie,
      Data_Fields.codigoSenal,
      Data_Fields.imgSenal,
      Data_Fields.formaSenal,
      Data_Fields.grupoSenalizacion,
      Data_Fields.dimensiones,
      Data_Fields.altura,
      Data_Fields.anchura,
      Data_Fields.diametro,
      Data_Fields.leyenda,
      Data_Fields.soporte,
      Data_Fields.reflectancia
    ]
  },
  [TYPES.BALIZAMIENTO]: {
    id: pointAssetIDFields,
    data: [
      Data_Fields.codigoBalizamiento,
      Data_Fields.tipoSoporteBalizamiento,
      Data_Fields.tipoSenalBalizamiento,
      Data_Fields.leyenda,
      Data_Fields.geometria
    ]
  },
  [TYPES.PASOS_MEDIANA]: {
    id: linearAssetIDFields,
    data: [Data_Fields.longitud, Data_Fields.anchura]
  },
  [TYPES.VIAS_PEAJES]: {
    id: linearAssetIDFields,
    data: [
      Data_Fields.equipo,
      Data_Fields.sistemaViasPeaje,
      Data_Fields.numViasEntrada,
      Data_Fields.numViasSalida,
      Data_Fields.numViasReversibles,
      Data_Fields.ancho,
      Data_Fields.anchoVias,
      Data_Fields.anchoViasMixtas
    ]
  },
  [TYPES.FIRMES]: {
    id: linearAssetIDFields,
    data: [
      Data_Fields.longitud,
      Data_Fields.anchura,
      Data_Fields.materialElemento,
      Data_Fields.anoColocacion
    ]
  },
  [TYPES.CAMARAS]: {
    id: pointAssetIDFields,
    data: commonITSDataFields
  },
  [TYPES.ESTACION_METEO]: {
    id: pointAssetIDFields,
    data: commonITSDataFields
  },
  [TYPES.PMV]: {
    id: pointAssetIDFields,
    data: commonITSDataFields
  },
  [TYPES.POSTES_SOS]: {
    id: pointAssetIDFields,
    data: commonITSDataFields
  },
  [TYPES.DESMONTES]: {
    id: linearStructureAssetIDFields,
    data: commonDataOtherStructures,
    auscultData: commonAuscultData
  },
  [TYPES.TERRAPLENES]: {
    id: linearStructureAssetIDFields,
    data: commonDataOtherStructures,
    auscultData: commonAuscultData
  },
  [TYPES.TUNELES]: {
    id: linearStructureAssetIDFields,
    data: [...commonDataOtherStructures, Data_Fields.superficieSeccionTunel],
    auscultData: commonAuscultData
  },
  [TYPES.BACULOS]: {
    id: pointAssetIDFields,
    data: baculoDataFields
  }
}

export function getFormFieldsForAssetType(assetType) {
  if (!TYPES[assetType]) {
    throw new Error(
      'getFormFieldsForAssetType - unknown assetType: ' + assetType
    )
  }
  return AssetTypeFields[assetType] || []
}

export function getIDFormFieldsForAssetType(assetType) {
  return getFormFieldsForAssetType(assetType).id.map(field =>
    field.name === 'codigoi3met' ? { ...field, name: 'codigo' } : field
  )
}

export function getDataFormFieldsForAssetType(assetType) {
  return getFormFieldsForAssetType(assetType).data
}

export function getAuscultFormFieldsForAssetType(assetType) {
  return getFormFieldsForAssetType(assetType).auscultData || []
}

export function getNormalizeFormFieldsForAssetType(assetType) {
  return {
    id: getIDFormFieldsForAssetType(assetType),
    data: getDataFormFieldsForAssetType(assetType),
    auscultData: getAuscultFormFieldsForAssetType(assetType)
  }
}

export function getFlattenFormFieldsForAssetType(assetType) {
  return [
    ...getIDFormFieldsForAssetType(assetType),
    ...getDataFormFieldsForAssetType(assetType),
    ...getAuscultFormFieldsForAssetType(assetType)
  ]
}

export const DATA_BLOCK_LABELS_ASSET = {
  id: 'Identificación',
  data: 'Datos',
  auscultData: 'Auscultaciones'
}
